.sidebar {
  width: 250px;
  background-color: #34495e;
  color: #fff;
  padding: 20px;
  height: 100vh;
  animation: slideIn 1s ease-out; /* Анимация выезда сайдбара */
}

a {
  text-decoration: none;
}

.sidebar h1 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #fff;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin: 15px 0;
  opacity: 0;
  animation: fadeInUp 0.5s ease-out forwards;
}

.sidebar ul li:nth-child(1) {
  animation-delay: 0.2s;
}
.sidebar ul li:nth-child(2) {
  animation-delay: 0.4s;
}

.sidebar ul li p {
  text-decoration: none;
  color: #fff;
  padding: 10px 15px;
  display: block;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.sidebar ul li p:hover,
.sidebar ul li p.active {
  background-color: var(--primary-color-hover);
  transform: scale(1.05); /* Увеличение при наведении */
}