body {
  margin: 0;
  font-family: Arial, sans-serif;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
}
  
.auth-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 30px;
}

.pass-recover-navlink {
  width: 250px;
}

.pass-recover {
  width: 250px;
  color: #666666;
}
.pass-recover:hover {
  color: #000000;
}


.container {
  text-align: center;
  background-image: url('./img/bk-image.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


h1 {
  font-size: 2rem;
  color: rgb(255, 255, 255);
  margin-bottom: 2rem;
}

button {
  background-color: #4A4A4A;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  margin: 0 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

button:hover {
  background-color: #6c6c6c;
}
/* Основной стиль для контейнера */
.container {
  text-align: center;
  background-image: url('./img/bk-image.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Стиль для кнопок */
button {
  background-color: #4A4A4A;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  margin: 0 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

button:hover {
  background-color: #6c6c6c;
}

/* Стили для модального окна */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px;
  text-align: left;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.submit-btn {
  background-color: #4A4A4A;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 1.2rem;
}

.submit-btn:hover {
  background-color: #6c6c6c;
}


/* pass show */

.password-field {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input {
  padding: 8px;
  width: 200px;
}


.password-input-container {
  position: relative;
}

.toggle-password-btn {
  background-color: none;
  box-shadow: none;
  border: none;
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.toggle-password-btn:hover {
  background-color: rgba(0, 0, 0, 0);
}


.show-pass {
  background-image: url('./img/visibility.svg');
  width: 25px;
  height: 20px;
}

.show-not-pass {
  background-image: url('./img/visibilityoff.svg');
  width: 25px;
  height: 20px;
}