.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Затемнение фона */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Поверх остального контента */
  }
  
  .modal-content {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 400px; /* Фиксированная ширина */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Тень для объема */
    animation: fadeIn 0.3s ease-in-out; /* Анимация появления */
  }
  
  .modal-content h2 {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
  }
  
  
  .modal-content .form-modal {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .modal-content .form-modal label {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }
  
  .modal_checkbox {
    display: flex;
    justify-content: space-between;
  }

  .modal-content .form-modal input[type="text"] {
    width: 250px;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .modal-content .modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .modal-content .btn {
    background-color: #007bff; /* Синий цвет кнопки */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .modal-content .btn:hover {
    background-color: #0056b3; /* Более темный синий при наведении */
  }
  
  /* Анимация для плавного появления */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  