/* Общие стили */
body {
  display: block;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%; /* Важно */
}

.body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
  color: #333;
  background-color: #f8f9fa;
  animation: fadeIn 1s ease-in-out; /* Анимация появления страницы */
}

.uppermain {
  display: flex;
  flex-direction: column;
}

h1, h2, h3 {
  margin: 0;
}

/* Основные цвета */
:root {
  --primary-color: #3498db; /* Основной синий цвет */
  --primary-color-hover: #2980b9; /* Тёмно-синий для эффектов */
  --background-color: #f8f9fa;
  --text-color: #333;
  --light-grey: #f2f2f2;
  --grey: #ddd;
}

/* Сайдбар */


/* Основной контент */
.main-content {
  flex-grow: 1;
  padding: 20px;
  animation: fadeIn 1.2s ease-in-out;
}

header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.settings-btn {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.settings-btn:hover {
  background-color: var(--primary-color-hover);
  transform: scale(1.1); /* Лёгкое увеличение при наведении */
}

.news-section, .transport-section {
  width: 70vw;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  margin-left: 20px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease-out forwards;
}

.news-section {
  animation-delay: 0.2s;
}

.transport-section {
  animation-delay: 0.4s;
}

.news-item {
  margin-bottom: 15px;
  opacity: 0;
  animation: fadeInUp 0.5s ease-out forwards;
}

/* неплохая практика анимаций */

/* .news-item:nth-child(1) {
  animation-delay: 0.6s;
}

.news-item:nth-child(2) {
  animation-delay: 0.8s;
} */

.date {
  font-size: 0.9rem;
  color: #777;
}

.actions {
  display: flex;
  gap: 10px;
  margin: 20px 0px;
}

.search-bar {
  flex-grow: 1;
  padding: 5px 10px;
  border: 1px solid var(--grey);
  border-radius: 5px;
  transition: box-shadow 0.3s;
}

.search-bar:focus {
  box-shadow: 0 0 5px var(--primary-color);
  outline: none;
}

.btn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin: 0px;
  font-size: medium;
}

.btn:hover {
  background-color: var(--primary-color-hover);
  transform: scale(1.05); /* Увеличение при наведении */
}

/* Таблица */
table {
  width: 100%;
  border-collapse: collapse;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease-out forwards;
  animation-delay: 0.6s;
}

th, td {
  border: 1px solid var(--grey);
  padding: 10px;
  text-align: left;
}

th {
  background-color: var(--light-grey);
}

.empty {
  text-align: center;
  color: #777;
}

/* Анимации */
@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
      transform: translateX(-100%);
  }
  to {
      transform: translateX(0);
  }
}


/* Футер */
.footer {
  background-color: #34495e;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer p {
  margin: 5px 0;
}

.footer p:first-child {
  font-weight: bold;
}



  



  /* Стили для модального окна */
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .form-modal {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top:15px ;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
    text-align: left;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input[type="text"] {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .checkbox-group {
    display: flex;
    align-items: center;
  }
  
  .checkbox-group label {
    margin-right: 10px;
  }
  
  .modal-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .modal-buttons button {
    flex: 1;
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: #4A4A4A;
    color: white;
  }
  
  .modal-buttons button:hover {
    background-color: #6c6c6c;
  }
  
  .modal_input_form {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  .toggle-switch {
    display: inline-block;
    cursor: pointer;
    width: 60px;
    height: 30px;
    background-color: #ccc;
    border-radius: 15px;
    position: relative;
    transition: background-color 0.3s;
  }
  
  .toggle-switch.on {
    background-color: #4caf50;
  }
  
  .toggle-knob {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 50%;
    transition: left 0.3s;
  }
  
  .toggle-switch.on .toggle-knob {
    left: 30px;
  }
  