header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  gap: 20px;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 25px;
}

.settings-btn {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-size: small;
}

.settings-btn:hover {
  background-color: var(--primary-color-hover);
  transform: scale(1.1); /* Лёгкое увеличение при наведении */
}


/* код для настройки */
/* Начальное скрытое состояние панели */
/* Основной контейнер панели */
.settings-panel {
  position: fixed;
  top: 10%;
  right: 10%;
  width: 400px;
  padding: 20px;
  box-sizing: border-box;
  z-index: 100;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Заголовки */
.settings-header,
.settings-section-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Группа настроек */
.settings-group {
  margin-bottom: 20px;
}

/* Элемент настройки */
.settings-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #42454a;
}

.settings-label {
  font-size: 14px;
  font-weight: bold;
}

.settings-value {
  flex: 1;
  margin: 0 10px;
  font-size: 14px;
}

/* Кнопка действия */
.settings-action {
  background-color: #4f545c;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.settings-action:hover {
  background-color: #3b4046;
}

/* Кнопка для смены пароля */
.change-password-btn {
  width: 100%;
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.change-password-btn:hover {
  background-color: #2980b9;
}

/* Ссылка (показать/скрыть email) */
.settings-link {
  color: #5865f2;
  margin-left: 5px;
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
}

.settings-link:hover {
  color: #4752c4;
}

/* Кнопка закрытия */
.settings-close-btn {
  background-color: #f04747;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  font-size: 14px;
  transition: background-color 0.3s;
}

.settings-close-btn:hover {
  background-color: #d13636;
}


